import Flickity from 'flickity';

window.addEventListener('load', function () {
  const mainSlider = new Flickity('#mainSlider',{
    autoPlay: true,
    wrapAround: true,
    contain: true,
    prevNextButtons: false
  })
  $('.topslider--arw-r').on('click', function (e) {
    e.preventDefault()
    mainSlider.next()
  })
  $('.topslider--arw-l').on('click', function (e) {
    e.preventDefault()
    mainSlider.previous()
  })

})